<div class="modal-header">
  <h3>Send message:</h3>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">x</button>
</div>
<div class="modal-body container">
  <div *ngIf="!sendToAllValue" class="container">
    <div class="row">
      <span class="box col-sm-auto row" *ngFor="let username of messageList">
        <span class="box-content col-sm-auto">{{username}}</span>
        <span class="remove col-sm-auto" (click)="removeFromList(username)">x</span>
        </span>
    </div>
  </div>
  <form class="mt-2" #sentMessage="ngForm" (ngSubmit)="handleFormSubmit(sentMessage)">
    <div class="form-check">
      <label class="form-check-inline" for="sendToAll">Send to all users </label>
      <input type="checkbox" class="form-check-input m-1" id="sendToAll" placeholder="" name="sendToAll"
             #sendToAll="ngModel"
             [(ngModel)]="sendToAllValue">

    </div>
    <div class="form-group mr-1">
      <label for="content">Content:</label>
      <textarea class="form-control" id="content" placeholder="" name="content" required [maxLength]="1000" rows="6"
                #content="ngModel"
                [(ngModel)]="contentValue"></textarea>
      <div class="alert " *ngIf="content.touched && content.invalid">
        <span class="alert-danger" *ngIf="content?.errors?.required"> Content is required</span>
        <span class="alert-danger" *ngIf="content?.errors?.maxLength"> Content is too long</span>
      </div>

    </div>

    <div class="form-row">
      <div class="col-sm-9"></div>
      <div class="col-sm-1">
        <div class="form-group">
          <button type="reset" class="btn btn-warning text-right">Clear</button>
        </div>
      </div>
      <div class="col-sm-1"></div>
      <div class="col-sm-1">
        <div class="form-group">
          <button type="submit" class="btn btn-primary text-right">Send</button>
        </div>
      </div>
    </div>

  </form>
</div>
