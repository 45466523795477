import {Component, OnInit} from '@angular/core';
import {HelpService} from '../../../services/help.service';
import {HelpDTO} from '../../../model/admin-dto';

const locale = 'EN';

@Component({
  selector: 'app-english',
  templateUrl: './english.component.html',
})
export class EnglishComponent implements OnInit {
  help: HelpDTO = {} as HelpDTO;


  constructor(private helpService: HelpService) {
  }

  ngOnInit(): void {
    this.helpService.getHelp(locale).subscribe(value => {
      this.help = value;
    }, () => {
      this.help.content = '';
      this.help.locale = locale;
    });
  }

  save() {
    this.helpService.saveHelp(this.help).subscribe();
  }
}
