import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {SpecialDatesComponent} from './view/special-dates/special-dates.component';
import {AppAuthGuard} from './guards/auth.guard';
import {UsersComponent} from './view/users/users.component';
import {MessagesListComponent} from './view/messages-list/messages-list.component';
import {HelpComponent} from './view/help/help.component';

const routes: Routes = [
  {
    path: '',
    component: UsersComponent,
    canActivate: [AppAuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'dates',
    component: SpecialDatesComponent,
    canActivate: [AppAuthGuard],
    pathMatch: 'full',
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AppAuthGuard],
    pathMatch: 'full'
  },
  {
    path: 'messages',
    component: MessagesListComponent,
    canActivate: [AppAuthGuard],
    pathMatch: 'full'
  },
  {
    path: 'help',
    component: HelpComponent,
    canActivate: [AppAuthGuard],
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
