import {Component, OnInit} from '@angular/core';
import {MessageFilterDTO, PageDetailedMessageDTO} from '../../model/admin-dto';
import {MessagesService} from '../../services/messages.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {InfoModalComponent} from '../../modals/info-modal/info-modal.component';

@Component({
  selector: 'app-messages-list',
  templateUrl: './messages-list.component.html',
  providers: [MessagesService]
})
export class MessagesListComponent implements OnInit {
  page: PageDetailedMessageDTO | undefined;
  filter: MessageFilterDTO = {direction: 'DESC', page: 0, sort: 'SENT_TIME', username: ''};

  constructor(private messagesService: MessagesService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.getMessages(0);
  }

  getMessages(page: number) {
    this.filter.page = page;
    this.messagesService.getSentMessages(this.filter).subscribe(value => {
      this.page = value;
    });
  }

  search() {
    this.getMessages(0);
  }

  openMessageDetails(content: string | DocumentFragment) {
    const modal = this.modalService.open(InfoModalComponent);
    modal.componentInstance.content = content;
  }
}
