<div class="modal-header">
  <h2>Add new date:</h2>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">x</button>
</div>
<div class="modal-body container">
  <form #dateForm="ngForm" (ngSubmit)="handleFormSubmit(dateForm)">
    <div class="form-group">
      <label for="specialDate">New holiday date:</label>
      <input type="date" class="form-control" id="specialDate" placeholder="" name="date" required
             #specialDate="ngModel"
             [min]="minDate"
             [(ngModel)]="date">
      <div *ngIf="specialDate.touched && specialDate.invalid" class="alert alert-danger">
        <div *ngIf="specialDate?.errors?.required">Date is required</div>
      </div>
      <label for="reason">Reason:</label>
      <input type="text" class="form-control" id="reason" placeholder="Reason" name="reason"
             #reason="ngModel"
             max="30"
             [pattern]="'^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 _-]{0,30}$'"
             [(ngModel)]="reasonValue">
      <div *ngIf=" reason.invalid" class="alert alert-danger">
        <div *ngIf="reason?.errors?.max">Reason is too long</div>
        <div *ngIf="reason?.errors?.pattern">Reason is invalid</div>
      </div>
    </div>
    <div class="form-row">
      <input type="checkbox" class="form-check" id="repeatable" name="repeatable"
             label="Repeatable"
             #repeatable="ngModel"
             [(ngModel)]="repeatableValue"/>
      <label class="form-check-label ml-1" for="repeatable">repeatable</label>
    </div>
    <div class="form-row">
      <div class="col-8"></div>
      <div class="col-1">
        <div class="form-group">
          <button type="reset" class="btn btn-sm btn-warning text-right">Clear</button>
        </div>
      </div>
      <div class="col-1"></div>
      <div class="col-1">
        <div class="form-group">
          <button type="submit" class="btn btn-sm btn-primary text-right">Save</button>
        </div>
      </div>
    </div>
  </form>
</div>
