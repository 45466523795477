<div class="pages" *ngIf="page">
    <span id="prev" class="switcher" (click)="prevPage(page.number)"
          [ngClass]="page.first ? 'blocked' : 'usable'">
        <
    </span>
  <span
    *ngIf="( page.number >= page.totalPages - 3 && page.totalPages >= 6) || (page.number >= 3 &&
        page.totalPages >= 3)"><span (click)="switchPage(0)" class="switcher">1<span
    class="margin-left-5" *ngIf="(page.number>=3)">...</span></span>

    </span>
  <span *ngIf="page.number <= 1 || page.totalPages <= 3">
        <span (click)="switchPage(0)" class="switcher"
              [ngClass]="page.number === 0 ? 'active' : 'inactive'">1</span>
        <span (click)="switchPage(1)" *ngIf="page.totalPages >= 2" class="switcher"
              [ngClass]="page.number === 1 ? 'active' : 'inactive'">2</span>
        <span (click)="switchPage(2)" *ngIf="page.totalPages >= 3" class="switcher"
              [ngClass]="page.number === 2 ? 'active' : 'inactive'">3</span>
    </span>

  <span *ngIf="(page.number >= 2 && page.number <= page.totalPages - 3) && page.totalPages >= 4">
        <span (click)="switchPage(page.number-1)" class="switcher">{{page.number}}</span><span
    class="active switcher">{{page.number + 1}}</span>
        <span (click)="switchPage(page.number+1)" class="switcher">{{page.number + 2}}</span>
    </span>

  <span *ngIf="(page.number > page.totalPages - 3 && page.totalPages >= 6)
     || (page.number >= page.totalPages - 2 && page.totalPages >= 4)">
        <span class="switcher" (click)="switchPage(page.totalPages-3)"
              [ngClass]="page.number === page.totalPages - 3 ? 'active' : 'inactive'">{{page.totalPages - 2}}</span>
        <span class="switcher" (click)="switchPage(page.totalPages-2)"
              [ngClass]="page.number === page.totalPages - 2 ? 'active' : 'inactive'">{{page.totalPages - 1}}</span>
        <span class="switcher" (click)="switchPage(page.totalPages-1)"
              [ngClass]="page.number === page.totalPages - 1 ? 'active' : 'inactive'">{{page.totalPages}}</span>
    </span>
  <span
    *ngIf="(page.number >= 0 && page.number < page.totalPages - 2 && page.totalPages >= 6)
        || (page.number <= 1 && page.totalPages >= 4)">
        <span *ngIf="page.totalPages-page.number<=3 else ellipsis "></span>
        <ng-template #ellipsis>...</ng-template>
        <span class="switcher" (click)="switchPage(page.totalPages-1)">{{page.totalPages}}</span></span>
  <span id="next" class="switcher" (click)="nextPage(page.number)"
        [ngClass]="page.last ? 'blocked' : 'usable'"> > </span>
  <span *ngIf="page.numberOfElements">

        {{showPagesElementsSize()}}
    of {{page.totalElements}}
</span>
</div>
