import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MessagesService} from '../../../services/messages.service';
import {NgForm} from '@angular/forms';
import {MessageDTO} from '../../../model/admin-dto';
import {ToastrService} from 'ngx-toastr';


@Component({
  selector: 'app-create-message',
  templateUrl: './create-message.component.html',
  styleUrls: ['./create-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessagesService]
})
export class CreateMessageComponent implements OnInit {
  contentValue = '';

  @Input()
  messageList: string[] = [];
  sendToAllValue = false;
  @Output() messageListEvent = new EventEmitter<string[]>();

  constructor(public activeModal: NgbActiveModal,
              private messagesService: MessagesService,
              private toast: ToastrService) {
  }

  ngOnInit(): void {
  }

  handleFormSubmit(sentMessage: NgForm) {
    if (sentMessage.invalid) {
      sentMessage.control.markAllAsTouched();
      return;
    }
    const message: MessageDTO = {
      content: sentMessage.controls.content.value,
      recipient: this.sendToAllValue ? [] : this.messageList,
      sendToAll: this.sendToAllValue
    };
    if (!this.sendToAllValue && this.messageList.length === 0) {
      return;
    }
    this.messagesService.sendMessage(message).subscribe(value => {
      if (value) {
        this.toast.success('Message sent');
        this.activeModal.close(true);
      } else {
        this.toast.error('Message not send');
      }
    }, () => {
      this.toast.error('Message not send');
    });
  }

  removeFromList(username: any) {
    this.messageList.forEach((item, index) => {
      if (item === username) {
        this.messageList.splice(index, 1);
        this.messageListEvent.emit(this.messageList);
      }
    });
  }
}
