<mat-tab-group>
  <mat-tab label="English">
    <ng-template matTabContent>
      <app-english></app-english>
    </ng-template>
  </mat-tab>
  <mat-tab label="Other">
    <ng-template matTabContent>
      <app-other [locale]="'PL'"></app-other>
    </ng-template>
  </mat-tab>
</mat-tab-group>
