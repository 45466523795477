import {Component, OnInit} from '@angular/core';
import {UsersService} from '../../services/users.service';
import {BlockUserDTO, FilterDTO, PageUserDTO, UserDTO} from '../../model/admin-dto';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AddUserComponent} from './add-user/add-user.component';
import {ConfirmModalComponent} from '../../modals/confirm-modal/confirm-modal.component';
import {ToastrService} from 'ngx-toastr';
import {CreateMessageComponent} from '../messages-list/create-message/create-message.component';
import {KeycloakInfoService} from '../../services/keycloak-info.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  providers: [UsersService, KeycloakInfoService]
})
export class UsersComponent implements OnInit {

  constructor(private usersService: UsersService,
              private modalService: NgbModal,
              private toast: ToastrService,
              private keycloakInfoService: KeycloakInfoService) {
    this.users = UsersComponent.clearUserPage();
    this.filter = {
      enabled: true,
      page: 0,
      search: '',
      sort: 'USERNAME',
      direction: 'ASC'
    };
  }

  users: PageUserDTO;
  page = 0;
  currentPage = 0;
  changeText = false;
  filter: FilterDTO;
  messageList: string[] = [];

  roles: string[] = [];
  groups: string[] = [];
  time: string[] = [];
  locale: string[] = [];

  private static clearUserPage() {
    return {
      content: [],
      empty: false,
      first: false,
      last: false,
      number: 0,
      numberOfElements: 0,
      pageable: {
        offset: 0,
        pageNumber: 0,
        pageSize: 0,
        paged: false,
        sort: {empty: false, sorted: false, unsorted: false},
        unpaged: false
      },
      size: 0,
      sort: {empty: false, sorted: false, unsorted: false},
      totalElements: 0,
      totalPages: 0
    };
  }

  ngOnInit(): void {
    this.getUsers(0);
    this.keycloakInfoService.getRoles().subscribe(value => {
      this.roles = value;
    });
    this.keycloakInfoService.getGroups().subscribe(value => {
      this.groups = value;
    });
    this.keycloakInfoService.getLocale().subscribe(value => {
      this.locale = value;
    });
    this.keycloakInfoService.getTime().subscribe(value => {
      this.time = value;
    });
  }

  getUsers(page: number) {
    this.filter.page = page;
    this.users = UsersComponent.clearUserPage();
    this.usersService.getUsersList(this.filter).subscribe(response => {
      this.users = response;
      this.filter.page = response.number;
    });
  }


  openAdd() {
    const modal = this.modalService.open(AddUserComponent, {
      size: 'xl', backdrop: 'static',
      keyboard: false
    });
    modal.componentInstance.locale = this.locale;
    modal.componentInstance.groups = this.groups;
    modal.componentInstance.roles = this.roles;
    modal.componentInstance.time = this.time;
    modal.dismissed.subscribe(value => {
      this.fetchDataAfterDialogClose(value);
    });
  }

  openEdit(user: UserDTO) {
    const modal = this.modalService.open(AddUserComponent, {
      size: 'xl', backdrop: 'static',
      keyboard: false
    });
    modal.componentInstance.editMode = true;
    modal.componentInstance.locale = this.locale;
    modal.componentInstance.groups = this.groups;
    modal.componentInstance.roles = this.roles;
    modal.componentInstance.time = this.time;
    modal.componentInstance.user = user;
    modal.closed.subscribe(value => {
      this.fetchDataAfterDialogClose(value);
    });
  }

  openBlock(user: UserDTO) {
    const modal = this.modalService.open(ConfirmModalComponent);
    if (user.enabled) {
      modal.componentInstance.header = 'Block user';
      modal.componentInstance.content = ' Are you sure you want to block this user?';
    } else {
      modal.componentInstance.header = 'Unblock user';
      modal.componentInstance.content = ' Are you sure you want to unblock this user?';
    }

    modal.closed.subscribe(value => {
      if (value) {
        const block: BlockUserDTO = {enabled: !user.enabled, username: user.username};
        this.usersService.blockUser(block).subscribe(response => {
          this.fetchDataAfterDialogClose(response);
          if (response) {
            user.enabled ? this.toast.success('User is blocked now ') : this.toast.success('User is unblocked now');
          } else {
            this.toast.error('User status not changed', 'Error');
          }
        });
      }
    });
  }


  clearFilter() {
    this.users.number = 0;
    this.filter = {
      enabled: true,
      page: 0,
      search: '',
      sort: 'USERNAME',
      direction: 'ASC'
    };
    this.getUsers(0);
  }

  private fetchDataAfterDialogClose(value: boolean) {
    if (value) {
      this.getUsers(0);
    }
  }


  changeDirection(field: string) {
    this.filter.sort = field;
    const filterDirection = this.filter.direction;
    if (filterDirection === 'ASC') {
      this.filter.direction = 'DESC';
    } else {
      this.filter.direction = 'ASC';
    }
    this.getUsers(this.users.number);
  }

  addToMailList(username: string, $event: any) {
    if ($event?.target?.checked) {
      this.messageList.push(username);
    } else {
      this.messageList.forEach((item, index) => {
        if (item === username) {
          this.messageList.splice(index, 1);
        }
      });
    }
  }

  sentMessage() {
    const modal = this.modalService.open(CreateMessageComponent, {size: 'lg'});
    modal.componentInstance.messageList = this.messageList;
    modal.componentInstance.messageListEvent.subscribe((value: string[]) => {
      this.messageList = value;
    });
    modal.closed.subscribe(() => {
      this.messageList = [];
    });
  }

  search() {
    this.getUsers(0);
  }

  openDelete(user: UserDTO) {
    const modal = this.modalService.open(ConfirmModalComponent);
    modal.componentInstance.header = 'Delete user';
    modal.componentInstance.content = ' Are you sure you want to delete user?';
    modal.closed.subscribe(value => {
      if (value) {
        this.usersService.deleteUser(user.username).subscribe(response => {
          if (response) {
            this.toast.success('User deleted');
            this.getUsers(0);
          } else {
            this.toast.error('User not delete.', 'Error');
          }
        }, () => {
          this.toast.success('User not delete Try again later.', 'Error');
        });
      }
    });
  }

  openVerify(user: UserDTO) {
    const modal = this.modalService.open(ConfirmModalComponent);
    modal.componentInstance.header = 'Send verify mail';
    modal.componentInstance.content = ' Are you sure you want to send verify mail?';
    modal.closed.subscribe(value => {
      if (value) {
        this.usersService.sendVerifyEmail(user.username).subscribe(response => {
          if (response) {
            this.toast.success('Mail sent');
          } else {
            this.toast.error('Mail not sent.', 'Error');
          }
        }, () => {
          this.toast.success('Mail not sent. Try again later.', 'Error');
        });
      }
    });
  }
}
