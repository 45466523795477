import {Component, OnInit} from '@angular/core';
import {SpecialDateService} from '../../services/special-date.service';
import {SpecialDateDTO} from '../../model/admin-dto';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AddSpecialDateComponent} from './add-special-date/add-special-date.component';

@Component({
  selector: 'app-special-dates',
  templateUrl: './special-dates.component.html',
  styleUrls: ['./special-dates.component.scss'],
  providers: [SpecialDateService]
})
export class SpecialDatesComponent implements OnInit {
  specialDates: SpecialDateDTO[] = [];


  constructor(private specialDateService: SpecialDateService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.getSpecialDates();
  }


  change(specialDateDTO: SpecialDateDTO) {
    const active = specialDateDTO.active;
    specialDateDTO.active = !active;
    this.specialDateService.updateActiveValue(specialDateDTO).subscribe(() => {
      this.getSpecialDates();
    });

  }


  private getSpecialDates() {
    this.specialDateService.getSpecialDates().subscribe(value => {
      this.specialDates = value;
    });
  }

  open() {
    const modal = this.modalService.open(AddSpecialDateComponent);
    modal.dismissed.subscribe(value => {
      if (value) {
        this.getSpecialDates();
      }
    });
  }
}
