import {Component, OnInit} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {Router} from '@angular/router';

interface MenuItem {
  name: string;
  url: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})


export class HeaderComponent implements OnInit {
  isVisible = false;

  menuItems: MenuItem[] = [
    {name: 'Special dates', url: '/dates'},
    {name: 'List of users', url: '/users'},
    {name: 'Help', url: '/help'},
    {name: 'Messages', url: '/messages'}];

  constructor(private keycloakService: KeycloakService, private router: Router) {
  }

  ngOnInit(): void {
  }


  handleToggleMenu(): void {
    this.isVisible = !this.isVisible;
  }

  logout() {
    this.keycloakService.logout().then(() => this.router.navigateByUrl('/'));
  }
}
