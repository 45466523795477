<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <button class="navbar-toggler" (click)="handleToggleMenu()" type="button">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" [class.show]="isVisible">
    <ul class="navbar-nav col-11">
      <li class="nav-item" *ngFor="let item of menuItems">
        <a class="nav-link" [routerLink]="item.url">{{item.name}}</a>
      </li>
    </ul>
    <ul class="navbar-nav navbar-right col-md-1 row">
      <li class="col-6"></li>
      <li class="nav-item col-5">
        <a class="nav-link" (click)="logout()">Logout</a>
      </li>
    </ul>
  </div>
</nav>
