<div class="table-responsive-sm">
  <table class="table ">
    <thead class="thead-dark">
    <tr>
      <th scope="col">#</th>
      <th scope="col">Recipent</th>
      <th scope="col">SentTime</th>
      <th scope="col">Content</th>
      <th scope="col">Read</th>
    </tr>
    <tr class="thead-light">
      <th scope="col"></th>
      <th scope="col"><input *ngIf="page" type="text" id="username" name="username" #username="ngModel"
                             [(ngModel)]="this.filter.username" (ngModelChange)="search()"></th>
      <th scope="col"></th>
      <th scope="col"></th>
      <th scope="col"></th>
    </tr>
    </thead>
    <tbody *ngIf="page">
    <tr *ngFor="let message of page.content;let i=index">
      <th scope="row" *ngIf="page.number==0">{{i + 1}}</th>
      <th scope="row" *ngIf="page.number>0">{{(i + 1) + ((page.number) * 10)}}</th>
      <td>{{message.recipient}}</td>
      <td>{{message.sentTime | date :'dd/MM/YYYY HH:MM:SS'}}</td>
      <td><span [innerText]="message.content | slice:0:50"></span>
        <span *ngIf="message.content.length>50" (click)="openMessageDetails(message.content)"><em
          class="fa fa-angle-down"> </em></span>
      </td>
      <td>{{message.read |yesNo}}</td>

    </tr>
    </tbody>
  </table>

  <app-pages *ngIf="page" [page]="page" (pageNumberChanged)="getMessages($event)"></app-pages>

</div>

