<div class="modal-header">
  <h2 class="modal-title" id="modal-title">{{header}}</h2>
  <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  {{content}}
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="activeModal.close(true)">Yes</button>
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss('cancel click')">No</button>

</div>
