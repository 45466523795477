import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MessageDTO, MessageFilterDTO, PageDetailedMessageDTO} from '../model/admin-dto';
import {Observable} from 'rxjs';

@Injectable()
export class MessagesService {

  PATH = 'api/message';


  constructor(private http: HttpClient) {
  }

  getSentMessages(filter: MessageFilterDTO): Observable<PageDetailedMessageDTO> {
    return this.http.post <PageDetailedMessageDTO>(`${this.PATH}/list`, filter);
  }

  sendMessage(message: MessageDTO): Observable<boolean> {
    return this.http.post <boolean>(`${this.PATH}`, message);
  }
}
