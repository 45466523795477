<div class="table-responsive-lg">
  <table class="table table-sm">
    <thead class="thead-dark">
    <tr>
      <th colspan="1"></th>
      <th colspan="12">
        <form #searchForm="ngForm" (ngSubmit)="search()" class="row">
          <div class="col-6">
            <input id="search" class="search" type="text" #searchValue="ngModel"
                   placeholder="username,first name, last name, email"
                   [(ngModel)]="filter.search">
          </div>
          <div class="col-6">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="enabledYes" [value]="true"
                     [(ngModel)]="filter.enabled">
              <label class="form-check-label" for="enabledYes">Yes</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="inlineRadioOptions" id="enabledNo" [value]="false"
                     [(ngModel)]="filter.enabled">
              <label class="form-check-label" for="enabledNo">No</label>
            </div>

            <button class="btn btn-sm btn-secondary mr-1" type="submit"><i class="fa fa-search"></i>
            </button>
            <button class="btn btn-sm btn-warning" (click)="clearFilter()"><i class="fa fa-refresh"></i></button>
          </div>
        </form>
      </th>

    </tr>
    <tr class="thead-light table-header">
      <th scope="col">#</th>
      <th scope="col" (click)="changeDirection('USERNAME')">
        <span [ngClass]="{'text-primary':filter.sort==='USERNAME'}">Username
        <span *ngIf="filter.sort==='USERNAME'">{{filter.direction === 'ASC' ? '^' : 'v'}}</span></span></th>
      <th scope="col">First name</th>
      <th scope="col">Last name</th>
      <th scope="col">Email</th>
      <th scope="col">Organization</th>
      <th scope="col">Phone</th>
      <th scope="col" (click)="changeDirection('GROUP')">    <span [ngClass]="{'text-primary':filter.sort==='GROUP'}">Group
        <span *ngIf="filter.sort==='GROUP'">{{filter.direction === 'ASC' ? '^' : 'v'}}</span></span>
      </th>
      <th scope="col">Time</th>
      <th scope="col">Resp unit</th>
      <th scope="col">Role</th>
      <th scope="col">Enabled</th>
      <th scope="col">
        <button class="btn btn-sm btn-secondary m-1" (click)="openAdd()"><i class="fa fa-plus"></i></button>
      </th>
      <th scope="col">
        <button class="btn btn-sm btn-primary m-1" (click)="sentMessage()"><i class="fa fa-envelope"></i></button>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let user of users.content;let i=index">
      <th scope="row" *ngIf="users.number==0">{{i + 1}}</th>
      <th scope="row" *ngIf="users.number>0">{{(i + 1) + ((users.number) * 10)}}</th>
      <td>{{user.username}}</td>
      <td>{{user.firstName}}</td>
      <td>{{user.lastName}}</td>
      <td>{{user.email}}</td>
      <td>{{user.organization}}</td>
      <td>
        <div class="row"><span *ngIf="user.phone">{{user.phone[0]}}</span>
          <div class="btn-group" *ngIf="user.phone && user.phone.length>1">
            <a id="dropdownMenuPhone"
               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <em class="fa fa-angle-down"> </em>
            </a>
            <div class="dropdown-menu dropdown-menu-left" style="right: 0; left: auto;"
                 aria-labelledby="dropdownMenuPhone">
              <div *ngFor="let number of user.phone|slice:1">
                <div>{{number}}</div>
              </div>
            </div>
          </div>
        </div>
      </td>
      <td>{{user.group}}</td>
      <td>{{user.time}}</td>
      <td>{{user.responsibleUnit}}</td>
      <td>{{user.role}}</td>
      <td>{{user.enabled |yesNo}}</td>
      <td>
        <div class="table-buttons">
          <div class="btn-group">
            <div class="btn-group dropleft" role="group">
              <button type="button" id="dropdownMenuButton" class="btn btn-sm m1 menu-button"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-ellipsis-v "></i>
              </button>
              <div class="dropdown-menu dropdown-menu-md-left" aria-labelledby="dropdownMenuButton">
                <a class="dropdown-item" (click)="openEdit(user)">Edit</a>
                <a class="dropdown-item" (click)="openBlock(user)">{{user.enabled ? 'Block' : 'Unblock'}}</a>
                <a class="dropdown-item" (click)="openDelete(user)">Delete user</a>
                <a class="dropdown-item" (click)="openVerify(user)">Send verify email</a>
              </div>
            </div>
          </div>
        </div>
      </td>
      <td>
        <input type="checkbox" [checked]="messageList.includes(user.username,0)"
               class="message-check"
               (change)="addToMailList(user.username, $event)">

      </td>
    </tr>
    </tbody>
  </table>

  <app-pages [page]="users" (pageNumberChanged)="getUsers($event)"></app-pages>

</div>

