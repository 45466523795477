import {Component} from '@angular/core';
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Panel administracyjny';


  constructor(private meta: Meta,
              private titleService: Title) {
    this.setLanguage();
  }

  private setLanguage() {
    this.meta.addTags([
      {name: 'description', content: 'Panel administracyjny'},
      {name: 'author', content: 'PAŻP'},
    ]);
    this.titleService.setTitle('Administration panel');
  }
}
