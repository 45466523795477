import {ApplicationRef, NgModule} from '@angular/core';


import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {SpecialDatesComponent} from './view/special-dates/special-dates.component';
import {AppAuthGuard} from './guards/auth.guard';
import {environment} from '../environments/environment';
import {HeaderComponent} from './view/header/header.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {YesNoPipe} from './pipes/yes-no.pipe';
import {UsersComponent} from './view/users/users.component';
import {ActivePipe} from './pipes/active.pipe';
import {RouterModule} from '@angular/router';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule, DatePipe} from '@angular/common';
import {MatPaginatorModule} from '@angular/material/paginator';
import {ToastNoAnimationModule, ToastrModule} from 'ngx-toastr';
import {AddUserComponent} from './view/users/add-user/add-user.component';
import {AddSpecialDateComponent} from './view/special-dates/add-special-date/add-special-date.component';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {BrowserModule} from '@angular/platform-browser';
import {ConfirmModalComponent} from './modals/confirm-modal/confirm-modal.component';
import {PagesComponent} from './view/pages/pages.component';
import {SpinnerOverlayComponent} from './model/spinner-overlay/spinner-overlay.component';
import {SpinnerInterceptor} from './interceptors/spinner-interceptor';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MessagesListComponent} from './view/messages-list/messages-list.component';
import {CreateMessageComponent} from './view/messages-list/create-message/create-message.component';
import {InfoModalComponent} from './modals/info-modal/info-modal.component';
import {QuillModule} from 'ngx-quill';
import {HelpComponent} from './view/help/help.component';
import {MatTabsModule} from '@angular/material/tabs';
import {EnglishComponent} from './view/help/english/english.component';
import {OtherComponent} from './view/help/other/other.component';

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote'],
    [{header: 1}, {header: 2}],               // custom button values
    [{list: 'ordered'}, {list: 'bullet'}],
    [{indent: '-1'}, {indent: '+1'}],          // outdent/indent
    [{direction: 'rtl'}],                         // text direction
    [{size: ['small', false, 'large', 'huge']}],  // custom dropdown
    [{header: [1, 2, 3, 4, 5, 6, false]}],
    [{color: []}, {background: []}],          // dropdown with defaults from theme
    [{font: []}],
    [{align: []}],
    ['clean'],                                         // remove formatting button
    ['image']                         // link and image, video
  ]
};

const keycloakService = new KeycloakService();

@NgModule({
  exports: [FormsModule, ReactiveFormsModule],
  declarations: [
    AppComponent,
    SpecialDatesComponent,
    HeaderComponent,
    YesNoPipe,
    AddSpecialDateComponent,
    ActivePipe,
    UsersComponent,
    AddUserComponent,
    ConfirmModalComponent,
    PagesComponent,
    SpinnerOverlayComponent,
    MessagesListComponent,
    CreateMessageComponent,
    InfoModalComponent,
    HelpComponent,
    EnglishComponent,
    OtherComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    KeycloakAngularModule,
    HttpClientModule,
    NgbModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CommonModule,
    MatPaginatorModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true
    }),
    ToastNoAnimationModule,
    MatProgressSpinnerModule,
    QuillModule.forRoot({modules}),
    MatTabsModule,
  ],
  providers: [{
    provide: KeycloakService,
    useValue: keycloakService,
  },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
    DatePipe,
    AppAuthGuard, NgbActiveModal]
})
export class AppModule {
  ngDoBootstrap(appRef: ApplicationRef) {
    keycloakService
      .init({
        config: {
          url: environment.keycloakConfig.url,
          realm: environment.keycloakConfig.realm,
          clientId: environment.keycloakConfig.clientId,
        },
        initOptions: {
          onLoad: 'login-required',
          checkLoginIframe: false,
        },
        enableBearerInterceptor: true,
        bearerExcludedUrls: ['/assets'],
      })
      .then(() => {
        console.log('[ngDoBootstrap] bootstrap app');

        appRef.bootstrap(AppComponent);
      })
      .catch((error: any) =>
        console.error('[ngDoBootstrapp] init Keycloak failed', error)
      );
  }
}
