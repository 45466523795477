import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Page} from '../../model/admin-dto';


@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PagesComponent implements OnInit {
  @Input() page: Page;
  @Output() pageNumberChanged = new EventEmitter<number>();


  constructor() {
    this.page = {
      empty: false,
      first: false,
      last: false,
      number: 0,
      numberOfElements: 0,
      pageable: {
        offset: 0,
        pageNumber: 0,
        pageSize: 0,
        paged: false,
        sort: {empty: false, sorted: false, unsorted: false},
        unpaged: false
      },
      size: 0,
      sort: {empty: false, sorted: false, unsorted: false},
      totalElements: 0,
      totalPages: 0
    };
  }

  ngOnInit(): void {
  }

  switchPage(pageNumber: number) {
    this.pageNumberChanged.emit(pageNumber);
  }

  prevPage(pageNumber: number) {
    if (this.page.totalPages > 1 && !this.page.first) {
      this.pageNumberChanged.emit(pageNumber - 1);
    }
  }

  nextPage(pageNumber: number) {
    if (this.page.totalPages > 1 && this.page.number !== this.page.totalPages - 1) {
      this.pageNumberChanged.emit(pageNumber + 1);
    }
  }

  showPagesElementsSize(): string {
    if (this.page.first) {
      return `1-${this.page.numberOfElements}`;
    }
    if (this.page.last) {
      return `${((this.page.number) * this.page.size) + 1}-${((this.page.number) * this.page.size + this.page.numberOfElements)}`;
    } else {
      return `${((this.page.number) * this.page.size) + 1}-${(this.page.number + 1) * this.page.size}`;
    }
  }


}
