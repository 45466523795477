<div class="table-wrapper-scroll-y table-scrollbar table-responsive-sm">
  <table class="table table-sm ">
    <thead class="thead-dark">
    <tr>
      <th scope="col">#</th>
      <th scope="col">Year</th>
      <th scope="col">Date</th>
      <th scope="col">Reasons</th>
      <th scope="col">Repeatable</th>
      <th scope="col">Actions
      </th>
      <th scope="col">
        <button class="btn btn-sm btn-secondary margin-left-10" (click)="open()"><i class="fa fa-plus"></i></button>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let date of specialDates;let i=index">
      <th scope="row">{{i + 1}}</th>
      <td [ngClass]="date.active?'text-success':'text-danger'">{{date.date | date : 'yyyy'}}</td>
      <td [ngClass]="date.active?'text-success':'text-danger'">{{date.date | date: 'MM-dd'}}</td>
      <td>{{date?.reason}}</td>
      <td>{{ date?.repeatable | yesNo }}</td>
      <td colspan="2">
        <button class="btn btn-sm btn-danger"
                (click)="change(date)">Delete
        </button>
      </td>
    </tr>
    </tbody>
  </table>
</div>
