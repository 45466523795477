import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ok-modal',
  templateUrl: './info-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoModalComponent implements OnInit {

  @Input()
  content = '';

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

}
