<div class="container-fluid container-lg">
  <div class="row">
    <div class="col"><h3>Edited by:</h3><h4>{{help.username}}</h4></div>
    <div class="col"><h3>Last modified:</h3><h4>{{help.updated | date : 'YYYY-MM-dd hh:mm:ss'}}</h4></div>

  </div>
  <h3>For all users:</h3>
  <div class="row">
    <div class="col editor">
      <quill-editor #english="ngModel" [(ngModel)]="help.content" format="html"
                    theme="snow"></quill-editor>
    </div>
  </div>
  <h3>For draw users</h3>
  <div class="row">
    <div class="col editor">
      <quill-editor #english="ngModel" [(ngModel)]="help.contentForDraw" format="html"
                    theme="snow"></quill-editor>
    </div>
  </div>

  <div class="form-row pt-5">
    <div class="col-11"></div>
    <div class="col-1">
      <div class="form-group">
        <button (click)="save()" class="btn btn-primary text-right">Save</button>
      </div>
    </div>
  </div>
</div>
