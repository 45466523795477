import {Component, Input, OnInit} from '@angular/core';
import {HelpService} from '../../../services/help.service';
import {HelpDTO} from '../../../model/admin-dto';

@Component({
  selector: 'app-other',
  templateUrl: './other.component.html',
})
export class OtherComponent implements OnInit {
  help: HelpDTO = {} as HelpDTO;
  @Input()
  locale = '';

  constructor(private helpService: HelpService) {
  }


  ngOnInit(): void {
    this.helpService.getHelp(this.locale).subscribe(value => {
      this.help = value;
    }, () => {
      this.help.content = '';
      this.help.locale = this.locale;
    });
  }

  save() {
    this.helpService.saveHelp(this.help).subscribe();
  }
}

