import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {SpecialDateDTO} from '../model/admin-dto';

@Injectable()
export class SpecialDateService {
  PATH = '/api/special-dates';

  constructor(private http: HttpClient) {

  }

  getSpecialDates(): Observable<SpecialDateDTO[]> {
    return this.http.get <SpecialDateDTO[]>(`${this.PATH}`);
  }

  updateActiveValue(specialDateDTO: SpecialDateDTO) {
    return this.http.put(`${this.PATH}`, specialDateDTO);
  }

  createSpecialDate(specialDateDTO: SpecialDateDTO) {
    return this.http.post(`${this.PATH}`, specialDateDTO);
  }

}
