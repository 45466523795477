<div class="modal-header">
  <h2>{{editMode ? 'Edit user' : 'Add new user'}}</h2>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">x</button>
</div>
<div class="modal-body container">

  <form [formGroup]="userForm" (ngSubmit)="handleFormSubmit()">
    <div class="form-row">
      <div class="col">
        <div class="form-group">
          <label for="username">Username</label>
          <input type="text" class="form-control" id="username" placeholder="" name="username"
                 [readOnly]="editMode" formControlName="username"
                 (input)="inputUsername()"
          >
          <div *ngIf="userForm.controls.username.touched && userForm.controls.username.invalid"
               class="alert alert-danger">
            <div *ngIf="userForm.controls.username?.errors?.required">Username is required</div>
            <div *ngIf="userForm.controls.username?.hasError('minlength')">Min length 8</div>
            <div *ngIf="userForm.controls.username?.hasError('maxlength')">Max length 20</div>
            <div *ngIf="userForm.controls.username?.hasError('pattern')">Username has incorrect format</div>
          </div>


          <label for="lastName">Last name</label>
          <input type="text" class="form-control" id="lastName" placeholder="" name="lastName"
                 formControlName="lastName">
          <div *ngIf="userForm.controls.lastName.touched && userForm.controls.lastName.invalid"
               class="alert alert-danger">
            <div *ngIf="userForm.controls.lastName?.errors?.required">Last name is required</div>
            <div *ngIf="userForm.controls.lastName?.hasError('minlength')">Min length 2</div>
            <div *ngIf="userForm.controls.lastName?.hasError('maxlength')">Max length 20</div>
            <div *ngIf="userForm.controls.lastName?.hasError('pattern')">Last name has incorrect format</div>
          </div>


          <label for="firstName">First name</label>
          <input type="text" class="form-control" id="firstName" placeholder="" name="firstName"
                 formControlName="firstName">
          <div *ngIf="userForm.controls.firstName.touched && userForm.controls.firstName.invalid"
               class="alert alert-danger">
            <div *ngIf="userForm.controls.firstName?.errors?.required">Fist name is required</div>
            <div *ngIf="userForm.controls.firstName?.hasError('minlength')">Min length 2</div>
            <div *ngIf="userForm.controls.firstName?.hasError('maxlength')">Max length 20</div>
            <div *ngIf="userForm.controls.firstName?.hasError('pattern')">First name has incorrect format</div>
          </div>
          <label for="email">Email</label>
          <input type="email" class="form-control" id="email" placeholder="" name="email" formControlName="email">
          <div *ngIf="userForm.controls.email.touched && userForm.controls.email.invalid" class="alert alert-danger">
            <div *ngIf="userForm.controls.email?.errors?.required">Email is required</div>
            <div *ngIf="userForm.controls.email?.errors?.email">Email has incorrect format</div>
          </div>

          <label for="group">Group</label>
          <select class="form-control" aria-label="Select user group" id="group" name="group" formControlName="group">
            <option *ngFor="let groupOptions of groups" [value]="groupOptions">{{groupOptions}}</option>
          </select>
          <label for="role">Role</label>
          <select class="form-control" aria-label="Select user role" id="role" name="role" formControlName="role">
            <option *ngFor="let roleOptions of roles" [value]="roleOptions">{{roleOptions}}</option>
          </select>

        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <div>
            <label for="time">Time</label>
            <select class="form-control" aria-label="Select user group" id="time" name="time" formControlName="time">
              <option *ngFor="let timeOptions of time" [value]="timeOptions">{{timeOptions}}</option>
            </select>
            <label></label>

            <label for="locale">Language</label>
            <select class="form-control" aria-label="Select user locale" id="locale" name="locale"
                    formControlName="locale">
              <option *ngFor="let localeOptions of locale" [value]="localeOptions">{{localeOptions}}</option>
            </select>

            <label for="organization">Organization</label>
            <input type="text" class="form-control" id="organization" placeholder="" name="organization"
                   formControlName="organization">
            <div
              *ngIf="userForm.controls.organization.touched && userForm.controls.organization.invalid"
              class="alert alert-danger">
              <div *ngIf="userForm.controls.organization?.hasError('maxlength')">Max length 50</div>
              <div *ngIf="userForm.controls.organization?.hasError('pattern')">Organization name has incorrect format
              </div>
            </div>

            <label for="responsibleUnit">Responsible unit</label>
            <input type="text" class="form-control" id="responsibleUnit" placeholder="" name="responsibleUnit"
                   formControlName="responsibleUnit" (input)="inputResponsibleUnit()">
            <div *ngIf="userForm.controls.responsibleUnit.touched && userForm.controls.responsibleUnit.invalid"
                 class="alert alert-danger">
              <div *ngIf="userForm.controls.responsibleUnit?.errors?.required">Responsible unit is required</div>
              <div *ngIf="userForm.controls.responsibleUnit?.hasError('minlength')">Min length 2</div>
              <div *ngIf="userForm.controls.responsibleUnit?.hasError('maxlength')">Max length 8</div>
              <div *ngIf="userForm.controls.responsibleUnit?.hasError('pattern')">Responsible unit has incorrect
                format
              </div>
            </div>

            <div formArrayName="phone">
              <div *ngFor="let value of phone.controls; let phoneValue=index">
                <label for="phone-{{ phoneValue }}">Phone:</label>
                <div class="input-group margin-bottom-0">
                  <input class="form-control" id="phone-{{ phoneValue }}" type="text" [formControlName]="phoneValue">
                  <div class="input-group-append mb-0">
                    <span *ngIf="phoneValue!=0" (click)="removePhone(phoneValue)"><i
                      class="fa fa-minus minus"></i></span>
                  </div>
                </div>
                <div *ngIf="phone.controls[phoneValue].touched && phone.controls[phoneValue].invalid"
                     class="alert alert-danger">
                  <div *ngIf="phone.controls[phoneValue]?.errors?.required">Phone is required</div>
                  <div *ngIf="phone.controls[phoneValue]?.errors?.pattern ">Phone has incorrect format</div>
                </div>
              </div>
              <button class="btn btn-sm " (click)="addPhone()" type="button">+ Add phone</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-10"></div>
      <div class="col-1">
        <div *ngIf="!editMode" class="form-group">
          <button type="reset" class="btn btn-warning text-right">Clear</button>
        </div>
      </div>
      <div class="col-1">
        <div class="form-group">
          <button type="submit" class="btn btn-primary text-right">Save</button>
        </div>
      </div>
    </div>
  </form>
</div>

