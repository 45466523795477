import {Component, OnInit} from '@angular/core';
import {KeycloakInfoService} from '../../services/keycloak-info.service';


@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  providers: [KeycloakInfoService]
})
export class HelpComponent implements OnInit {

  locale: string[] = [];

  constructor(private keycloakInfo: KeycloakInfoService) {
  }

  ngOnInit(): void {
    this.keycloakInfo.getLocale().subscribe(value => {
      this.locale = value;
    });
  }

}
